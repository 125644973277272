<template>
 <div>
  <div class="lg:hidden">
    <div class="px-6 py-2 flex flex-col font-semibold flex-grow lg:mt-32 lg:min-h-screen">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Compose Message</h2>
    </header>
    <BPageLoader v-if="msgSubjects.isLoading" class="flex-grow" />
    <main class="my-6 flex flex-col text-sm">
      <div class="flex-grow">
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Subject</label>
          <BModalSelect class="my-2" :options="subjects" v-model="subject" />
        </div>
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Message</label>
          <textarea
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            v-model="description"
            required
          />
        </div>
      </div>
      <div class="text-center">
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary text-sm
            rounded
            w-full
            my-4
            py-4
            font-bold
          "
          type="submit"
          @click="handleSubmit"
          :disabled="!isFilled || isLoading"
        >
          <BButtonLoader class="h-4 w-4 mx-auto" v-if="isLoading" />
          <span v-else>Send Message</span>
        </button>
      </div>
    </main>
  </div>
  </div>
  <div class="hidden lg:flex justify-center pt-4">
    <div class="b-card mt-24 pt-8">
      <div class="px-26 py-2 flex flex-col font-semibold flex-grow ">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Compose Message</h2>
    </header>
    <BPageLoader v-if="msgSubjects.isLoading" class="flex-grow" />
    <main class="my-6 flex flex-col text-sm">
      <div class="flex-grow">
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Subject</label>
          <BSelectInput class="my-2" :options="subjects" v-model="subject" />
        </div>
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Message</label>
          <textarea
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            v-model="description"
            required
          />
        </div>
      </div>
      <div class="text-center">
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary text-sm
            rounded
            w-full
            my-4
            py-4
            font-bold
          "
          type="submit"
          @click="handleSubmit"
          :disabled="!isFilled || isLoading"
        >
          <BButtonLoader class="h-4 w-4 mx-auto" v-if="isLoading" />
          <span v-else>Send Message</span>
        </button>
      </div>
    </main>
  </div>
    </div>
  </div>
 </div>
</template>

<script>
import { ref, computed, toRefs, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { postMessage } from '@/services/api';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useAppState } from '@/cmp-functions/appState.js';
import BModalSelect from '@/components/ui/BModalSelect';
import BSelectInput from '../../components/ui/BSelectInput.vue';

export default {
  name: 'ComposeMessage',

  components: { BModalSelect, BSelectInput },

  setup() {
    const subject = ref('');
    const description = ref('');
    const store = useStore();
    const authUser = store.state.auth.authData;
    const toast = useToast();

    const isFilled = computed(() => {
      return subject.value && description.value;
    });

    const [response, sendMessage] = useApi(postMessage);

    watch(response, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Sent!');
        subject.value = '';
        description.value = '';
      }
    });

    const handleSubmit = () => {
      if (isFilled.value) {
        const payload = {
          message: description.value,
          subjectId: subjectsList.value.find(({ description }) => description === subject.value)?.id,
          userId: authUser.id,
          sender: 2,
          recepient: 1,
        };

        sendMessage(payload);
      }
    };
    const { msgSubjects } = useAppState();

    const subjectsList = computed(() => Object.values(msgSubjects.data?.subjects || {}));

    const subjects = computed(() => subjectsList.value?.map(({ description}) => description));

    return {
      ...toRefs(response),
      isFilled,
      subject,
      description,
      handleSubmit,
      subjectsList,
      msgSubjects,
      subjects,
    };
  },
};
</script>
